import React from 'react'
import { removeSpecialChars } from '../../utils'

const highlightTerm = (text, term) => {
  const termIndex = text.search(new RegExp(removeSpecialChars(term), 'i'))
  const start = termIndex > 0 ? text.substring(0, termIndex) : null
  const termPart = text.substring(termIndex, termIndex + term.length)
  const end = termIndex + term.length < text.length ? text.substring(termIndex + term.length) : null
  return [start, termPart, end]
}

const Option = ({ term = '', link, text = '', isSelected, onSelect, pageMode }) => {
  const [startPart, termPart, endPart] = highlightTerm(text, term)
  const optionProps = {}
  if (isSelected) optionProps.className = 'sfe-active'

  return (
    <li
      onMouseDown={e => {
        onSelect(e, link, text, pageMode)
      }}
      {...optionProps}
    >
      <a href={link} className='sfe-search-dropdown-list-item-link'>
        {startPart}
        <strong>{termPart}</strong>
        {endPart}
      </a>
    </li>
  )
}

export default Option
